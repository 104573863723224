.ql-snow .ql-picker.ql-font .ql-picker-label::before {
    content: attr(data-value);
  }




.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Impact']::before,.ql-snow .ql-picker.ql-font .ql-picker-item[data-label='Impact']::before {
    content: 'Impact';
    font-family: 'Impact';
  }
  .ql-font-Impact { font-family: 'Impact'; }


.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times-new-roman']::before,.ql-snow .ql-picker.ql-font .ql-picker-item[data-label='times-new-roman']::before {
    content: 'times-new-roman';
    font-family: 'Times New Roman';
  }
  .ql-font-times-new-roman { font-family: 'Times New Roman'; }


.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='verdana']::before,.ql-snow .ql-picker.ql-font .ql-picker-item[data-label='verdana']::before {
    content: 'verdana';
    font-family: 'Verdana';
  }
  .ql-font-verdana { font-family: 'Verdana'; }


.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='roboto']::before,.ql-snow .ql-picker.ql-font .ql-picker-item[data-label='roboto']::before {
    content: 'roboto';
    font-family: 'Roboto';
  }
  .ql-font-roboto { font-family: 'Roboto'; }


.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='courier-new']::before,.ql-snow .ql-picker.ql-font .ql-picker-item[data-label='courier-new']::before {
    content: 'courier-new';
    font-family: 'Courier New';
  }
  .ql-font-courier-new { font-family: 'Courier New'; }

  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='helvetica']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-label='helvetica']::before {
    content: 'Helvetica';
    font-family: 'Helvetica';
  }
  .ql-font-helvetica { font-family: 'Helvetica'; }
  
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='impact']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-label='impact']::before {
    content: 'Impact';
    font-family: 'Impact';
  }
  .ql-font-impact { font-family: 'Impact'; }
  
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='georgia']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-label='georgia']::before {
    content: 'Georgia';
    font-family: 'Georgia';
  }
  .ql-font-georgia { font-family: 'Georgia'; }
  
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='tahoma']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-label='tahoma']::before {
    content: 'Tahoma';
    font-family: 'Tahoma';
  }
  .ql-font-tahoma { font-family: 'Tahoma'; }
  
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='trebuchet-ms']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-label='trebuchet-ms']::before {
    content: 'Trebuchet MS';
    font-family: 'Trebuchet MS';
  }
  .ql-font-trebuchet-ms { font-family: 'Trebuchet MS'; }
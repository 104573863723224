/* CustomQuill.css */

.create_blog .ql-editor {
    padding: 15px;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    box-shadow: 2px;
    max-height: 80vh;
    overflow: auto;
   
}

.ql-toolbar {
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    background-color: #fff;
    padding: 10px;
}

* {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
body::-webkit-scrollbar {
  width: 0.7em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px gray;
}

body::-webkit-scrollbar-thumb {
  background-color: #333;
}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',Arial, sans-serif;
    background-color: #f8f9fa;
    margin: 0;
    padding: 0;
}

.blog-container {
    width: 90%;
    max-width: 1400px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}
.left-section, .right-section {
    flex: 1;
}

.left-section {
    padding: 20px;
    border-right: 1px solid #ddd;
}

.right-section {
    padding: 20px;
}

.page-title {
    text-align: center;
    margin-bottom: 10px;
}

.sections-option {
    display: flex;
    flex-direction: column;
    /* width: 22%; */
}

.add-section-title {
    text-align: center;
    width: 22%;
}


.button-submit{
    padding: 15px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    
    margin-top: 2rem;
    margin-left: auto;
}
.button-add-section{
    padding: 15px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 4%;
    transition: background-color 0.3s ease;

}


.form-section {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    /* background-color: #fff; */
    /* border: 1px solid #ccc; */
    /* border-radius: 5px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-direction: column;


}

.form-section input,
.form-section textarea {
    width: 100%;
    padding: 10px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.form-section textarea {
    resize: vertical;
}

.schedule-section {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 1rem;
}

.form-control {
    width: 100%;
    padding: 0.5rem;
}





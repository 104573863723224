.blog-page-header {
  margin-top: 0.7em;
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 0.7em;
}

.blog-home-container {
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  margin: 5px 5px;
}

.subtitle-feature-blog {
  font-size: 20px;
  font-style: italic;
  margin-bottom: 8px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.title-feature-blog {
  font-size: 2.2em;
  margin: 0 0 10px;
  word-wrap: break-word; /* Ensure long titles wrap to the next line */
  overflow-wrap: break-word; /* Break words if they are too long */
}

.left-half, .right-half {
  width: 48%;
  padding: 20px;
  border: 1px solid #ddd;
  box-sizing: border-box; /* Ensure padding and border are included in width/height */
}

.left-half {
  width: 48%;
  padding: 20px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  overflow: hidden; /* Prevent content from overflowing */
}
.image-container {
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.view-more {
  text-align: right;
  text-decoration: underline;
  margin-bottom: 0.5rem;
}

.right-half .blog-item {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
}

.right-half .blog-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.right-half .blog-item h3 {
  margin: 0 0 10px;
  font-size: 20px;
  color: #000000;
}

.right-half .blog-item p {
  margin: 0;
  font-size: 16px;
  color: #555;
}

.recent-blog-header {
  margin: 4px 4px;
}

.view-detail {
  margin-top: 1rem;
  text-decoration: underline;
}

.blog-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.blog-button:hover {
  background-color: #0056b3;
}

@media (max-width: 750px) {
  .blog-home-container {
    flex-direction: column;
  }
  
  .left-half, .right-half {
    width: 100%;
    padding: 10px;
  }

  .image-container img {
    width: 100%;
    height: auto;
  }
}

/* CustomQuill.css */

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
    content: '10px';
    font-size: 10px;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
    content: '12px';
    font-size: 12px;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
    content: '14px';
    font-size: 14px;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
    content: '16px';
    font-size: 16px;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
    content: '18px';
    font-size: 18px;
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
    content: '20px';
    font-size: 20px;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
    content: '24px';
    font-size: 24px;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='30px']::before {
    content: '30px';
    font-size: 30px;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='36px']::before {
    content: '36px';
    font-size: 36px;
  }



  .ql-snow .ql-picker.ql-size .ql-picker-label::before {
    content: attr(data-value);
  }
  .ql-editor {
    font-size: 16px; /* Set a default font size for the editor */
    
  }
  
  .ql-editor ul, .ql-editor ol {
    padding-left: 1.5em; /* Adjust padding for list items */
  }
  
  .ql-editor li {
    font-size: inherit; /* Inherit font size from parent (default or selected font size) */
  }
  
  /* Set default font-family */
/* Custom background color picker */
.ql-customBackground::before {
  content: 'BG';
}
.ql-hr::before {
  content: 'hr';
}
/* .ql-font-courier { font-family: 'Courier'; }
.ql-font-comic { font-family: 'Comic Sans MS'; } */



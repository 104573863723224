.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 400px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #aaa;
  transition: color 0.2s ease-in-out;
}

.close-popup:hover {
  color: #000;
}

.popup h2 {
  margin: 0 0 15px;
  font-size: 24px;
}

.popup p {
  margin-bottom: 20px;
}

.popup form input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.popup form button {
  padding: 10px 20px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.popup form button:hover {
  background-color: #0056b3;
}

/* InternalServerError.css */
.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    color: #343a40;
    text-align: center;
  }
  
  .error-page h1 {
    font-size: 10rem;
    margin: 0;
  }
  
  .error-page p {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
  
  .error-page button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .error-page button:hover {
    background-color: #0056b3;
  }
  
.all-blog-container {
    text-align: center;
    padding: 20px;
  }
  
  .blog-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .blog-item {
    display: flex;
    align-items: center;
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    background-color: #fff;
  }
  
  .blog-image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .blog-content {
    flex-grow: 1;
    text-align: start;
  }
  
  .blog-content h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  .blog-content p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #777;
  }
  
  .blog-content a {
    text-decoration: none;
    color: inherit;
  }
  .pagination button {
    background-color: blue;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .pagination button:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
  
  
  @media (max-width: 750px) {
    .blog-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .blog-image {
      margin: 0 0 10px 0;
      width: 100%;
      height: auto;
    }
  }
  
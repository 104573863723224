.btn-container {
  background-color: #FFF6F6;
  padding: 8px;
  text-align: center;
  border-bottom: 2px solid darkgray;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.popup-text {
  margin-right: 10px;
}

.bold-text {
  font-weight: bold;
}

.play-quiz-btn {
  text-decoration: none;
  background: #de00a5;
  padding: 6px 10px;
  border-radius: 5px;
  color: white;
  width: fit-content;
  cursor: pointer;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
  margin-right: 15px;
}
  
  .play-quiz-btn:hover {
    /* border: none; */
    background-color: #2541fc;
  }
  
  .close-icon{
    cursor: pointer;
  }
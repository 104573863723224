body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    background-color: #fff;
    margin: 0;
    padding: 0;
    overflow-y: auto;


}

.show-blogPage {
    display: flex;
    margin: 0 10%;
}

.sidebar {
    flex: 1;
    margin-top: 5rem;
    margin-right: 0.4em;
}

.sidebar-content{
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 1em;
    border-radius: 5px;
}
.sidebar-page-section-header{
    font-size: 2em;
    margin-bottom: 0.6em;
}

.normal{
    font-weight: 100;
    font-size: 0.6em;
}


.sidebar-blog-items li:hover {
    text-decoration: underline;
    font-weight:400;
    
}
.sidebar-blog-items  {
    margin-bottom: 0.8em;
    
}




.sidebar-item {
    margin: 10px 0;
}
.divider {
    border-top: 1px solid #ccc;
    margin: 10px 0;
  }

.social-icons {
    display: flex;
    align-items: center;
  }
  
  .social-icon {
    margin-right: 10px; /* Adjust spacing between icons */
  }
   .instagram{
    height: 30px;
    width: 30px;
    /* width: 10%; Adjust spacing between icons */
  }
/* .social-icons {
    width: 20px;
   
    margin-right: 10px;
    cursor: pointer;
} */
.max-height-40vh {
    
    height: auto;
    width: 100%;
    
    padding-left: 10%; /* Additional padding on left for spacing */
    padding-right: 10%;
}


.page-container {
    flex: 2;
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    

}


/* .top-component{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.blogTitle{
    text-align: center;
} */
.blogAuthor {
    font-style: italic;
    /* text-align: center; */
    margin: 1rem;
}
.blogSubtitle {
    /* text-align: center; */
    margin: 0.4rem;
}

.blog-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}


@media (max-width: 750px) {
    .show-blogPage {
        flex-direction: column;
    }

    .sidebar,
    .page-container {
        flex: none;
        width: 100%;
        height: auto;
    }

    .sidebar {
        margin-top: 1rem;
    }
    .tableofcontent{
        display: none;
    }
}
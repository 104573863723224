nav {
    width: 220px;
    min-width: 220px;
    padding: 16px;
    align-self: flex-start;
    position: -webkit-sticky;
    position: sticky;
    /* top: 48px; */
    max-height: calc(100vh - 70px);
    overflow: auto;
    /* margin-top: 50px; */
  }
  
  nav ul li {
    margin-bottom: 15px;
  }
  /* nav {
    padding: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
  } */
  
  nav ul {
    list-style: none;
    padding: 0;
  }
  
  nav li {
    margin-bottom: 0.5rem;
  }
  
  nav a {
    text-decoration: none;
    color: #9172c2;
  }
  
  nav a:hover {
    text-decoration: underline;
  }
  
  /* Styles for different heading levels */
  .head2 {
    margin-left: 0;
    font-weight: bold;
  }
  
  .head3 {
    margin-left: 1rem;
  }
  
  .head4 {
    margin-left: 2rem;
  }
  